<template>

  <div class="body">
  <div id="spacer"></div>
  <v-container>
    <div class="body-content">
        <div  align="left" >
        <label class="label-content">{{$t('Currency')}}</label>
            <v-autocomplete
                append-icon="mdi-chevron-down"
                :placeholder="$t('SelectCurrency')"
                v-bind:items="currencies"
                v-model="currency"
                item-text="name"
                item-value="code"
                :color="$partnerACL.getButtonColor()"
                outlined
                class="pt-1"
                dense
                @change="updateCurrency(currency)"
                >
                <template slot="item" slot-scope="data">
                <v-img maxHeight="28" maxWidth="28" :src="data.item.img" class="mr-3"></v-img>
                {{ data.item.name}}
                </template>
            </v-autocomplete>
        </div>
    </div>
  </v-container>
</div>
</template>

<script>
export default {
data() {
  return {
  name:"CurrencySetting",
  currencies: [{
        name:'USD - US Dollar',
        code:"USD",
        img:require('@/assets/country/USA.png')
      },
      {
        name:'VND - Vietnamese Dong',
        code:"VND",
        img:require('@/assets/country/vietnam.png')
      },
      {
        name:'IDR - Indonesian Rupiah',
        code:"IDR",
        img:require('@/assets/country/indonesia.png')
      },
    ],
    currency: null
  };
},
created() {
  this.currency = localStorage.getItem('currency') || "IDR"
  localStorage.setItem('currency', this.currency);
},
methods:{
  updateCurrency(currency){
    localStorage.setItem('currency', currency);
  }
}
};
</script>

<style scoped>
#spacer {
background: #f5fff7;
padding: 6px;
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
.body-space{
  background-color:#f5fdf7;
  height: 2%;
  width: 100%;
}
.body{
  background-color:#FFFFFF;
  height: 100%;
  width: 100%;
}
.logo-homepage{
  padding-top: 30px;
  padding-bottom: 30px;    
}
.img-body{
  height: 211px;
  width: 243.77471923828125px;
}
.label-content{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.body-content{
  padding-top: 10px;
  padding-bottom: 30px;
}

}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
.body-space{
  background-color:#f5fdf7;
  height: 3%;
  width: 100%;
}
.body{
  background-color:#FFFFFF;
  height: 100%;
  width: 100%;
}
.logo-homepage{
  padding-top: 30px;
  padding-bottom: 30px;    
}
.img-body{
  height: 211px;
  width: 243.77471923828125px;
}
.label-content{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.body-content{
  padding-top: 10px;
  padding-bottom: 30px;
}

}
</style>
